.lds-ring {
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid rgb(245, 32, 216); /* width and colour are overridden by style */
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; /* animationDuration is overridden by style */
  animation-delay: -9s; /* pretend we've already been animating for a while, so they don't all start at the top */
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
